import React, {useContext, useEffect, useState} from 'react';
import { Box, Container, Grid, Typography, Link, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import logo from '../assets/shared/jet-travel-logo-white.svg';
import mandatory from "../utils/mandatory";
import TikTokIcon from "../TikTokIcon";
import {facebook, instagram, tiktok} from "../utils/socials";
import {useTranslation} from "react-i18next";
import useCallDataApi from "../hooks/data";
import ConfigContext from "../context/ConfigContext";

const Footer = () => {
    const {t} = useTranslation()
    const {language} = useContext(ConfigContext);
    const {getData} = useCallDataApi('footer-links')
    const [links, setLinks] = useState([])
    useEffect(() => {
        getData().then(d => setLinks(d))
    }, []);
    return (
        <Box sx={{ bgcolor: 'primary.main', color: 'white', py: 3 }}>
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" gutterBottom>
                            {t('footer.aboutUs')}
                        </Typography>
                        <Box mb={2}>
                            <img src={logo} alt="Logo" style={{ maxWidth: '50%', height: 'auto' }} /> {/* Add the logo image */}
                        </Box>
                        <Typography variant="body2">
                            {t('footer.aboutUsDescription')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" gutterBottom>
                            {t('footer.links')}
                        </Typography>
                        {mandatory?.map(item => (
                            <Link key={item?.name} href={item?.url} color="inherit" variant="body2" display="block" gutterBottom>
                                {t(item?.name)}
                            </Link>
                        ))}
                    </Grid>
                    {links?.length > 0 && <Grid item xs={12} md={4}>
                        <Typography variant="h6" gutterBottom>
                            {t('footer.extraLinks')}
                        </Typography>
                        {links?.map(item => (
                            <Link key={item?.translations?.[language]?.name} href={item?.link} color="inherit" variant="body2" display="block"
                                  gutterBottom>
                                {item?.translations?.[language]?.name}
                            </Link>
                        ))}
                    </Grid>}
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" gutterBottom>
                            {t('footer.contact')}
                        </Typography>
                        <Typography variant="body2" display="block">
                            H-1113 Budapest, Bocskai út 77-79
                        </Typography>
                        <Typography variant="body2" display="block">
                            Tel.: +361 209 2110
                        </Typography>
                        <Typography variant="body2" display="block" gutterBottom>
                            Email: jettravel@jettravel.hu
                        </Typography>
                        <Box>
                            <IconButton
                                color="inherit"
                                component="a"
                                href={facebook}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FacebookIcon />
                            </IconButton>
                            <IconButton
                                color="inherit"
                                component="a"
                                href={tiktok}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <TikTokIcon width='22px' height='22px'/>
                            </IconButton>
                            <IconButton
                                color="inherit"
                                component="a"
                                href={instagram}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <InstagramIcon />
                            </IconButton>

                        </Box>
                    </Grid>
                </Grid>
                <Box textAlign="center" mt={5}>
                    <Typography variant="body2">
                        &copy; {new Date().getFullYear()} Jet Travel Kft. {t('footer.allRightsReserved')}
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;
